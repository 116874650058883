
import { defineComponent, ref, unref, toRefs, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import { RuleItem } from 'async-validator';
import { absDate } from '@/utils/absDate';
import { useUpdateUser, useUser, useCreateUser } from '@/composables/api';
import { PartialUser } from '@/interfaces/User';
import useFormErrors, { isValidPassword } from '@/utils/validate';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { handleClipboard } from '@/utils/clipboard';
import { normalizeRequestData } from '@/utils/normalization';
import { CREATED_SUCCESSFULLY_TEXT, getPlans, MAX_PER_PAGE, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';
import { CollectionTag } from '@element-plus/icons';
import SectionLayout from '@/components/SectionLayout.vue';
import 'vue3-country-intl/lib/vue3-country-intl.css';
import Vue3CountryIntl from 'vue3-country-intl';
import { Plan } from '@/interfaces';

const DEFAULT_FORM_VALUES: PartialUser & {
  subscriptionStartAt: string
  subscriptionEndAt: string
  verifiedType?: string
  planId?: number
  } = {
    username: '',
    realName: '',
    countryCode: '',
    phone: '',
    contactMail: '',
    password: '',
    birthday: '',
    walletAddress: '',
    subscriptionStartAt: '',
    subscriptionEndAt: '',
    comment: '',
    isBlack: false
  };

export default defineComponent({
  components: {
    SectionLayout,
    Vue3CountryIntl
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const userId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const plans = ref<any>([]);
    const currentSubscription = ref(null);
    const subscriptionDateRange = ref(['']);
    const isPlanDisabled = ref(false);
    const subscriptionDateRangeDisable = ref(false);

    const currentValidate = ref(null);

    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const isFormLoading = ref(true);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    const allPlans = ref<Plan[]>([]);

    const { isLoading: isCreating, mutate: create } = useCreateUser();
    const { isLoading: isUpdating, mutate: update } = useUpdateUser();
    const { formErrors, bindFormItemError } = useFormErrors();

    const { data, dataUpdatedAt } = useUser({ userId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const rules = {
      username: [
        {
          min: 2,
          max: 20,
          message: '限 2~20 字元，中英數字輸入，不包含特殊符號',
          required: true,
          trigger: 'blur'
        }
      ],
      contactMail: [
        {
          type: 'email',
          message: '需為有效信箱',
          required: !isEdit.value,
          trigger: 'blur'
        }
      ],
      password: [
        {
          type: 'password',
          required: !isEdit.value,
          trigger: 'change',
          validator: (rule: RuleItem, password: string) => {
            if (!rule.required && !password) {
              return true;
            }

            return isValidPassword(password);
          }
        }
      ],
      birthday: [
        {
          type: 'date'
        }
      ]
    };

    const disabledDate = (datesOnCalendar: any) => {
      const today = new Date();
      const yesterday = new Date().setDate(today.getDate() - 1);
      return datesOnCalendar.getTime() < yesterday;
    };

    const handleUserHasSubscription = () => {
      const userSubscription = data.value.data.subscription;

      const userHasNoSubscription = userSubscription === null;
      const userHasPlan = userSubscription?.plan !== null;
      const userHasCoupon = userSubscription?.coupon !== null;
      const userHasDefaultPlan = (userSubscription?.startedAt && userSubscription?.endedAt) !== null;

      if (userHasNoSubscription) {
        isPlanDisabled.value = false;
        subscriptionDateRange.value = [''];

        return;
      }

      if (userHasPlan) {
        isPlanDisabled.value = true;
        currentSubscription.value = userSubscription.plan?.name;

        return;
      }

      if (userHasCoupon) {
        isPlanDisabled.value = true;
        currentSubscription.value = '兌換訂閱';

        return;
      }

      if (userHasDefaultPlan) {
        // currentSubscription.value = '人工方案';
        isPlanDisabled.value = true;
      }
    };

    const getSubscriptionDateRange = () => {
      const subscription = data.value.data.subscription;
      subscriptionDateRange.value = [subscription?.startedAt, subscription?.endedAt];
    };

    const handleSubscriptionChange = () => {
      formValues.value.subscriptionStartAt = subscriptionDateRange.value[0];
      formValues.value.subscriptionEndAt = subscriptionDateRange.value[1];
    };

    const generatePassword = () => {
      formValues.value.password = '';

      const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const passwordLength = 20;

      for (let i = 0; i < passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        formValues.value.password += chars.substring(randomNumber, randomNumber + 1);
      }

      if (!formValues.value.password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/)) {
        return generatePassword();
      }

      return formValues.value.password;
    };

    const copyPassword = (event: MouseEvent) => {
      handleClipboard(formValues.value.password, event);
    };

    const yearBack18 = absDate(18);
    const defaultDate = ref(yearBack18);
    const rangeLimited = (time: Date) => {
      return time.getTime() > new Date(yearBack18).getTime();
    };

    const removeSubscribedDateTime = () => {
      const subscribed = data.value.data.subscription;
      if (formValues.value.subscriptionStartAt === subscribed?.startedAt) {
        delete formValues.value.subscriptionStartAt;
      }
      if (formValues.value.subscriptionEndAt === subscribed?.endedAt) {
        delete formValues.value.subscriptionEndAt;
      }
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            removeSubscribedDateTime();

            const { contactMail, ...otherFormValues } = formValues.value;
            update(
              {
                userId,
                data: normalizeRequestData(otherFormValues, { patternedStringKeys: ['walletAddress'] })
              },
              {
                onSuccess() {
                  ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                  router.go(-1);
                },
                onError(error: ResponseError) {
                  ElMessage.error(error.response?.data.message);
                  formErrors.value = error.response?.data.message;
                }
              }
            );
            return;
          }
          formValues.value.verifiedType = currentValidate.value;
          create(
            { data: normalizeRequestData(formValues.value, { patternedStringKeys: ['walletAddress'] }) },
            {
              onSuccess() {
                ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

                formValues.value = DEFAULT_FORM_VALUES;

                router.go(-1);
              },
              onError(error: ResponseError) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.message;
              }
            }
          );
        }
      });
    };

    const fetchPlans = async() => {
      allPlans.value = (await getPlans({ query: { pageSize: MAX_PER_PAGE } })).data.filter(plan => ['7日', '30日', '90日', '365日'].includes(plan.name.replace(/(\d+日).*/, '$1')));
    };

    const handleChangePlans = () => {
      if (currentSubscription.value > 0) {
        subscriptionDateRange.value = [''];
        formValues.value.subscriptionStartAt = '';
        formValues.value.subscriptionEndAt = '';
        formValues.value.planId = currentSubscription.value;
        subscriptionDateRangeDisable.value = true;
      } else {
        subscriptionDateRangeDisable.value = false;
      }
    };

    onMounted(() => {
      if (!isEdit.value) {
        isFormLoading.value = false;
      }
      fetchPlans();
    });

    watch(dataUpdatedAt, () => {
      const user = data.value.data;
      getSubscriptionDateRange();
      handleUserHasSubscription();

      formValues.value = {
        username: user.username,
        contactMail: user.contactMail,
        birthday: user.birthday,
        walletAddress: user.walletAddress,
        subscriptionStartAt: user.subscription?.startedAt,
        subscriptionEndAt: user.subscription?.endedAt,
        comment: user.comment,
        realName: user.realName,
        countryCode: user.countryCode,
        phone: user.phone,
        address: user.address,
        isBlack: user.isBlack
      };

      isFormLoading.value = false;
    });

    return {
      data,
      plans,
      rules,
      formRef,
      formValues,
      formErrors,
      defaultDate,
      isFormLoading,
      isPlanDisabled,
      isUpdating,
      isCreating,
      currentSubscription,
      subscriptionDateRange,
      defaultTime,
      allPlans,
      currentValidate,
      subscriptionDateRangeDisable,
      submitForm,
      rangeLimited,
      copyPassword,
      disabledDate,
      formatLocalTime,
      formatISOString,
      generatePassword,
      bindFormItemError,
      handleSubscriptionChange,
      handleChangePlans
    };
  }
});
