/**
 * 年份計算
 * ex:absDate(-18)
 * return 當前日減18年後的日期
 * @param years 想減少的年份
 * @returns 最後format後的日期 :string
 */
export const absDate = (years: number) => {
  const today = new Date();
  const nowYear = today.getFullYear();
  const nowMonth = today.getMonth();
  const nowDay = today.getDate();
  const yearsBack = new Date(nowYear - years, nowMonth, nowDay);
  return yearsBack;
};
